function getDecimalPay(numval) {
    numval = Math.floor(numval);
    numval = numval + "";
    var cde = 1, vv = "";
    for (var i = 0; i < numval.length; i++) {
        if (numval.charAt(i) != ",") vv += numval.charAt(i);
    }
    numval = vv;
    vv = "";

    for (i = numval.length - 1; i >= 0; i--) {
        if (i != 0 && cde == 3) {
            vv += numval.charAt(i) + ",";
            cde = 1
        } else {
            vv += numval.charAt(i);
            cde++;
        }
    }
    numval = "";
    for (var i = vv.length - 1; i >= 0; i--) {
        numval += vv.charAt(i);
    }
    return numval + "₮";
}

function valid_id(id) {
    return id !== undefined && id !== null && id !== ""
}


function readURL(input, div) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            div.html("<img class='preview-img' src='" + e.target.result + "'>");
        };
        reader.readAsDataURL(input.files[0]);
    }
}

$(window).on('load', function () {
});
$(document).ready(function () {
});